import './styles/Login-Form.css';


export const LoginForm = (props) => {
    const server = props.server;
    const setToken = props.tokenSetter;
    const btn_login = "btn_login";
    const lbl_status = "lbl_login_status";
    const txt_user = "txt_username";
    const txt_pass = "txt_password";

    // TODO shake on fail?

    const login_failed = () => {
        const login_status = document.getElementById(lbl_status);
        login_status.innerHTML = 'Login failed!';
        login_status.style.color = 'red';
        setToken(null);
    };

    const login = (e) => {
        e.preventDefault();

        const login_status = document.getElementById(lbl_status);
        login_status.style.color = 'black';
        login_status.innerHTML = 'Logging in...';

        const username = document.getElementById(txt_user).value;
        const password = document.getElementById(txt_pass).value;

        if (!username || !password)
        {
            const login_status = document.getElementById(lbl_status);
            login_status.innerHTML = 'Please enter username and password';
        }
        else
        {
            fetch(
                server.concat('/login'),
                {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    },
                    body: 'username=' + username + '&password=' + password,
                }
            )
            .then(response => {
                if (response.status !== 200) {
                    throw Error("Login failed.");
                }
                return response.json()
            })
            .then(json => {
                const cookie = {
                    access_token: json.access_token,
                }
                document.cookie = JSON.stringify(cookie);
                setToken(json.access_token);
            })
            .catch(error => {
                console.error(error);
                login_failed();
            })
        };
    };

    return (
        <div className="login-form">
            <form>
                <label>
                    Username: <input type="text" id={txt_user} name="username" />
                </label>
                <br />
                <label>
                    Password: <input type="password" id={txt_pass} name="password" />
                </label>
                <br />
                <button id={btn_login} onClick={login}>Login</button>
                <br />
                <label id={lbl_status}> </label>
            </form>
        </div>
    );
};

