import {useState, useEffect, useMemo} from 'react';
import {Box} from './Box';
import './styles/LogsViewer.css'


export const LogsViewer = (props) => {
    const [updateRate, setUpdateRate] = useState(2);
    const [numberOfLines, setNumberOfLines] = useState(10);
    const [logs, setLogs] = useState("");
    const [logTypes, setLogTypes] = useState({});
    const [logType, setLogType] = useState("");
    const [lastUpdate, setLastUpdate] = useState("Never");

    const server = props.server;
    const token = props.token;

    useMemo( () => {
        const url = `${server}/logs`;
        fetch(
            url,
            {
                method: 'GET',
                headers: {
                    accept: 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            }
        )
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {
            setLogTypes(json);
            setLogType(json[Object.keys(json)[0]]);
        })
        .catch(error => {
            alert(`Internal error: ${error.toString()}`);
            setLogs(
                `url: ${url}\r\nError reading available log types: ${error.toString()}`
            );
            console.error(error);
        });
    }, [server, token]);

    useEffect( () => {
        const getLogs = () => {
            const url = `${server}/logs/${logType}/logs/raw?lines=${numberOfLines}`;
            fetch(
                url,
                {
                    method: 'GET',
                    headers: {
                        accept: 'text/plain',
                        'Authorization': `Bearer ${token}`,
                    },
                }
            )
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.text();
            })
            .then(text => {
                // Remove leading and trainling quotes and new lines.
                text = text.replace(/(^(\\n)*"|(\\n)*"(\\n)*$)/g, "");
                setLogs(text.replaceAll("\\n", "\r\n"));
                const d = new Date()
                setLastUpdate(d.toUTCString());
            })
            .catch(error => {
                setLogs(
                    `url: ${url}\r\nError reading logs: ${error.toString()}`
                );
                console.error(error);
            });
        };

        let interval = null;
        if (updateRate > 0)
        {
            interval = setInterval(getLogs, updateRate * 1000);
        }
        return () => clearInterval(interval);
    }, [updateRate, numberOfLines, server, logType, token]);

    const valueChanged = (name, event, setValue, fallback) => {
        const strValue = event.target.value;
        const value = Number(strValue);
        if(isNaN(value))
        {
            alert(`Bad ${name} value (${strValue})`);
            setValue(fallback);
        }
        else
        {
            setValue(value);
        }
    };

    const updateRateChanged = (event) =>
        valueChanged("update rate", event, setUpdateRate, updateRate);

    const numberOfLinesChanged = (event) =>
        valueChanged("number of lines", event, setNumberOfLines, numberOfLines);

    const typeChanged = (event) => setLogType(event.target.value);

    return (
        <Box {...props} width="600" height="400" title="Logs Viewer">
            <div className="logs-viewer">
                <div id="logs-viewer-header-type">
                    <label>
                        Logs:
                        <select
                            onChange={typeChanged}
                            name="logs-type"
                            id="logs-type"
                            defaultValue={logType}
                        >
                            {
                                Object.keys(logTypes).map(
                                    key => <option
                                                key={key}
                                                value={logTypes[key]}
                                            >
                                                {key}
                                            </option>
                                )
                            }
                        </select>
                    </label>
                </div>
                <div id="logs-viewer-header-rate">
                    <label>
                        Update rate:
                        <input
                            type="text"
                            name="rate"
                            value={updateRate}
                            size="3"
                            onChange={updateRateChanged}
                        />
                        s
                    </label>
                </div>
                <div id="logs-viewer-header-lines">
                    <label>
                        # Lines:
                        <input
                            type="text"
                            name="numLines"
                            value={numberOfLines}
                            size="4"
                            onChange={numberOfLinesChanged}
                        />
                    </label>
                </div>
                <div id="logs-viewer-body">
                    <textarea
                        name="logs_area"
                        readOnly={true}
                        value={logs}
                        id="logs-viewer-textarea"
                    />
                </div>
                <div id="logs-viewer-footer">
                    <label>Updated: {lastUpdate}</label>
                </div>
            </div>
        </Box>
    );
};
