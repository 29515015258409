import React, { useState, createRef } from 'react';
import Draggable from 'react-draggable';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';
import './styles/Box.css';


export const Box = (props) => {
    const [size, setSize] = useState({
        width: Number(props.width),
        height: Number(props.height),
    });

    const ref = createRef();

    const closeBox = (e) => {
        props.hooks.close.fcn(props.hooks.close.props, props.boxes);
    }

    return (
        <Draggable
            nodeRef={ref}
            handle=".title-style"
            bounds="parent"
        >
            <div ref={ref}>
                <ResizableBox
                    width={size.width}
                    height={size.height}
                    onResize={(event, { size }) => setSize(size)}
                >
                    <div className="outter_box">
                        <button className="title-style">
                            {props.title}
                        </button>
                        <div className="inner_box">
                            <button className="close-button" onClick={closeBox}>
                                X
                            </button>
                            <div className="content_box">
                                {props.children}
                            </div>
                        </div>
                    </div>
                </ResizableBox>
            </div>
        </Draggable>
    );
};
