import { useState, useMemo} from 'react';
import {Box} from './Box';


export const ChangeVPNPort = (props) => {
    const [port, setPort] = useState(0);

    const server = props.server
    const port_url = server.concat('/network/vpn/interfaces/wg0/port')
    const token = props.token;

    const lbl_status = "lbl_port_set_status";
    useMemo( () => {
        async function getPort() {
            const response = await fetch(port_url);
            const json = await response.json();
            await setPort(Number(json.wg0));
        }
        getPort();
    }, [port_url]);

    const set_port = (e) => {
        const feedback = document.getElementById(lbl_status);
        feedback.innerHTML = 'Processing port change...';

        e.preventDefault();

        fetch(
            port_url,
            {
                method: 'PUT',
                headers: {
                    accept: 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ port })
            },
        )
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {
            feedback.innerHTML = `Port successfully set to ${json.port}.`;
        })
        .catch(error => {
            feedback.innerHTML = 'Failed to set port!'
            alert(error.toString());
        });
    };

    const portChanged = (event) => {
        const strPort = event.target.value;
        const new_port = Number(strPort);
        if(isNaN(new_port))
        {
            alert(`Bad port value (${strPort})`);
            setPort(port);
        }
        else
        {
            setPort(new_port);
        }
    };

    return (
        <Box {...props} width="250" height="100" title="Change VPN Port">
            <form onSubmit={set_port}>
                <label>
                    Port:
                        <input
                            type="text"
                            name="port"
                            value={port}
                            size="5"
                            onChange={portChanged}
                        />
                </label>
                <button type="submit">Set</button>
                <br />
                <label id={lbl_status}> </label>
            </form>
        </Box>
    );
};

